// Rutas de los proyectos
const proj_url = 'assets/img/projects/';
const tecno_url = 'assets/icons/tecnologies/';

// Categorias de los proyectos
export const categories = [
  { name: 'Web Design', icon: 'fa-regular fa-laptop-code' },
  { name: 'Applications', icon: 'fa-solid fa-mobile-screen-button' },
  { name: 'Web Development', icon: 'fa-regular fa-code' }
];

// Proyectos
export const cardsData = [
  {
    activeInitials: true,
    id: 11, category: categories[0]?.name,
    title: 'Dev Portfolio',
    icon_img: 'fa-solid fa-star',
    icon_name: 'This project',
    img: `${proj_url}fondo_negro.jpg`,
    tecnologies: [
      { icon: `${tecno_url}react.svg`, name: 'React' },
      { icon: `${tecno_url}javascript.svg`, name: 'JavaScript' },
      { icon: `${tecno_url}scss.svg`, name: 'Sass' },
    ],
    powereds: [
      { icon: 'fa-solid fa-lock', name: 'Private' },
      { icon: 'fa-solid fa-circle-info', name: 'Personal and professional web portfolio designed with a minimalist approach and dark colors.', position: 'top' },
    ]
  },
  {
    id: 10, category: categories[2]?.name,
    title: 'Academic Management System',
    icon_img: 'fa-solid fa-image',
    icon_name: 'View images',
    isGallery: true,
    img: `${proj_url}GestionAcademica.jpg`,
    images: [
      `${proj_url}GestionAcademica.jpg`,
      `${proj_url}gestionAcademica/arquitecture.jpg`,
      `${proj_url}gestionAcademica/arquitecture_backend.jpg`,
      `${proj_url}gestionAcademica/diagram.jpg`,
      `${proj_url}gestionAcademica/models.jpg`,
      `${proj_url}gestionAcademica/change_module.jpeg`,
      `${proj_url}gestionAcademica/home.jpeg`,
      `${proj_url}gestionAcademica/register.jpg`,
      `${proj_url}gestionAcademica/historic_notes.jpeg`,
      `${proj_url}gestionAcademica/students.jpg`,
      `${proj_url}gestionAcademica/predictions.jpeg`,
      `${proj_url}gestionAcademica/prediction_teacher.jpg`,
      `${proj_url}gestionAcademica/prediction_student.jpeg`,
      `${proj_url}gestionAcademica/dashboard_general.jpeg`,
      `${proj_url}gestionAcademica/dashboard_statistics.jpeg`,
      `${proj_url}gestionAcademica/dashboard_tasa.jpeg`,
      `${proj_url}gestionAcademica/dashboard_catedra.jpeg`,
      `${proj_url}gestionAcademica/manuals.jpg`,

    ],
    tecnologies: [
      { icon: `${tecno_url}angular.svg`, name: 'Angular' },
      { icon: `${tecno_url}nodejs.svg`, name: 'NodeJs' },
      { icon: `${tecno_url}python.svg`, name: 'Python' },
      { icon: `${tecno_url}powerbi.svg`, name: 'Power BI' },
      { icon: `${tecno_url}mongo.svg`, name: 'Mongo DB' },
      { icon: `${tecno_url}mysql.svg`, name: 'MySQL' },
    ],
    powereds: [
      { icon: 'fa-solid fa-lock', name: 'Private' },
      { icon: 'fa-solid fa-microchip-ai', name: 'Artificial Inteligence' },
      { icon: 'fa-solid fa-circle-info', name: 'Student grade prediction system and statistics of the information technology career of the Universidad de las Fuerzas Armadas ESPE.', position: 'top' },
    ]
  },
  { // Ocultar por el momento
    id: 9, category: categories[2]?.name,
    title: 'Crop Prediction System',
    icon_img: 'fa-solid fa-image',
    icon_name: 'View images',
    isGallery: true,
    img: `${proj_url}ConteoCultivos.jpg`,
    images: [
      `${proj_url}ConteoCultivos.jpg`,
      `${proj_url}conteoCultivos/login.jpeg`,
      `${proj_url}conteoCultivos/register.jpeg`,
      `${proj_url}conteoCultivos/analysis.jpeg`,
      `${proj_url}conteoCultivos/new_analysis.jpeg`,
      `${proj_url}conteoCultivos/users.jpeg`,
      `${proj_url}conteoCultivos/settings.jpeg`,
      `${proj_url}conteoCultivos/report.jpg`,
    ],
    tecnologies: [
      { icon: `${tecno_url}angular.svg`, name: 'Angular' },
      { icon: `${tecno_url}fastapi.svg`, name: 'Fast Api' },
      { icon: `${tecno_url}python.svg`, name: 'Python' },
      { icon: `${tecno_url}mysql.svg`, name: 'MySQL' },
    ],
    powereds: [
      { icon: 'fa-solid fa-lock', name: 'Private' },
      { icon: 'fa-solid fa-microchip-ai', name: 'Artificial Inteligence' },
      { icon: 'fa-solid fa-circle-info', name: 'Cocoa and banana crop counting system using a supervised learning algorithm.', position: 'top' },
    ]
  },
  {
    id: 7, category: categories[2]?.name,
    title: 'Plataform Water Just',
    icon_img: 'fa-solid fa-image',
    icon_name: 'View images',
    isGallery: true,
    img: `${proj_url}PlataformJuntaAgua.jpg`,
    images: [
      `${proj_url}PlataformJuntaAgua.jpg`,
      `${proj_url}plataformJuntaAgua/login.jpeg`,
      `${proj_url}plataformJuntaAgua/panel.jpeg`,
      `${proj_url}plataformJuntaAgua/clients.jpeg`,
      `${proj_url}plataformJuntaAgua/metters.jpeg`,
    ],
    tecnologies: [
      { icon: `${tecno_url}angular.svg`, name: 'Angular' },
      { icon: `${tecno_url}nodejs.svg`, name: 'NodeJs' },
      { icon: `${tecno_url}sqlserver.svg`, name: 'SQL Server' }
    ],
    powereds: [
      { icon: 'fa-solid fa-lock', name: 'Private' },
      { icon: 'fa-solid fa-circle-info', name: 'Platform for the control and maintenance of the water distribution board.', position: 'top' }
    ]
  },
  {
    id: 6, category: categories[2]?.name,
    title: 'Website SCIEDTEC',
    icon_img: 'fa-solid fa-globe',
    icon_name: 'View web',
    url: 'https://sciedtec.com',
    img: `${proj_url}WebsiteSciedtec.jpg`,
    tecnologies: [
      { icon: `${tecno_url}wordpress.svg`, name: 'Wordpress' },
      { icon: `${tecno_url}elementor.svg`, name: 'Elementor' },
      { icon: `${tecno_url}mysql.svg`, name: 'MySQL' },
    ],
    powereds: [
      { icon: 'fa-solid fa-circle-info', name: 'SCIEDTEC main website.', position: 'top' }
    ]
  },
  {
    id: 5, category: categories[1]?.name,
    title: 'Qr Scanner Attacs XSS',
    icon_img: 'fa-brands fa-github',
    icon_name: 'View repository',
    url: 'https://github.com/JosueAVD27/Qr-Scanner-XSS',
    img: `${proj_url}AttacXSS.jpg`,
    tecnologies: [
      { icon: `${tecno_url}kotlin.svg`, name: 'Kotlin' },
      { icon: `${tecno_url}jetpackcompose.svg`, name: 'Jetpack compose' }
    ],
    powereds: [
      { icon: 'fa-brands fa-android', name: 'Android' },
      { icon: 'fa-solid fa-circle-info', name: 'Application for detecting XSS attacks in QR codes.', position: 'top' }
    ],
    download: ['fa-brands fa-android', 'Download APK', 'assets/docs/apk/QrScannerXSS.apk']
  },
  {
    id: 4, category: categories[1]?.name,
    title: 'Base App',
    icon_img: 'fa-brands fa-github',
    icon_name: 'View repository',
    url: 'https://github.com/JosueAVD27/BaseApp_Flutter',
    img: `${proj_url}BaseAppFlutter.jpg`,
    tecnologies: [
      { icon: `${tecno_url}flutter.svg`, name: 'Flutter' },
      { icon: `${tecno_url}dart.svg`, name: 'Dart' }
    ],
    powereds: [
      { icon: 'fa-brands fa-android', name: 'Android' },
      { icon: 'fa-brands fa-app-store-ios', name: 'iOS' },
      { icon: 'fa-solid fa-circle-info', name: 'Base application with the most common functionalities for a mobile application.', position: 'top' }
    ],
    download: ['fa-brands fa-android', 'Download APK', 'assets/docs/apk/BaseApp.apk']
  },
  {
    id: 3, category: categories[0]?.name,
    title: 'Plataform Web',
    icon_img: 'fa-brands fa-github',
    icon_name: 'View repository',
    url: 'https://github.com/JosueAVD27/Estructura-Web-PHP',
    img: `${proj_url}PlataformaWeb.jpg`,
    tecnologies: [
      { icon: `${tecno_url}php.svg`, name: 'PHP' },
      { icon: `${tecno_url}javascript.svg`, name: 'JavaScript' },
      { icon: `${tecno_url}scss.svg`, name: 'Sass' },
      { icon: `${tecno_url}mysql.svg`, name: 'MySQL' },
    ],
    powereds: [
      { icon: 'fa-solid fa-circle-info', name: 'Responsive web platform base with crud and user management.', position: 'top' }
    ]
  },
  {
    id: 2, category: categories[0]?.name,
    title: 'Portfolio Web',
    icon_img: 'fa-brands fa-github',
    icon_name: 'View repository',
    url: 'https://github.com/JosueAVD27/Plantilla-Porfolio',
    img: `${proj_url}Portafolio.jpg`,
    tecnologies: [
      { icon: `${tecno_url}php.svg`, name: 'PHP' },
      { icon: `${tecno_url}javascript.svg`, name: 'JavaScript' },
      { icon: `${tecno_url}css.svg`, name: 'Css' }
    ],
    powereds: [
      { icon: 'fa-solid fa-circle-info', name: 'Simple personal web portfolio.', position: 'top' }
    ]
  },
  {
    id: 1, category: categories[2]?.name,
    title: 'Website Security ESPE',
    icon_img: 'fa-solid fa-image',
    icon_name: 'View images',
    isGallery: true,
    img: `${proj_url}VinculacionESPE.jpg`,
    images: [
      `${proj_url}VinculacionESPE.jpg`,
      `${proj_url}vinculacionESPE/home.jpeg`,
      `${proj_url}vinculacionESPE/community.jpeg`,
      `${proj_url}vinculacionESPE/statistics.jpg`,
      `${proj_url}vinculacionESPE/about.jpg`,
    ],
    tecnologies: [
      { icon: `${tecno_url}angular.svg`, name: 'Angular' },
      { icon: `${tecno_url}nodejs.svg`, name: 'NodeJs' },
      { icon: `${tecno_url}mongo.svg`, name: 'Mongo DB' },
      { icon: `${tecno_url}css.svg`, name: 'Css' },
    ],
    powereds: [
      { icon: 'fa-solid fa-lock', name: 'Private' },
      { icon: 'fa-solid fa-circle-info', name: 'Page focused on showing a detailed statistic about the security of Canton Luz de America and ESPE SD.', position: 'top' }
    ]
  }
];
